import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Hamburger from "./hamburger"

const Header = ({ siteTitle }) => {
  // Header drop shadow
  if (typeof window !== `undefined`) {
    const body = document.body
    let lastScroll = 0

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset
      if (currentScroll <= 0) {
        body.classList.remove("shadow")
        return
      }
      if (currentScroll > lastScroll) {
        body.classList.add("shadow")
        return
      }
    })
  }

  return (
    <header>
      <div className="header__nav">
        <div className="header__nav--title">
          <Link to="/">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
              <path
                d="M25.9542 50.8707C26.1861 47.5991 29.2934 40.7126 29.2934 40.7126C29.2934 40.7126 34.8306 43.8844 33.9617 48.7514C33.0927 53.6184 30.5451 60 30.5451 60C30.5451 60 25.7222 54.1424 25.9542 50.8707Z"
                fill="#FFC30E"
              />
              <path
                d="M34.2206 9.12928C33.9887 12.4009 30.8814 19.2874 30.8814 19.2874C30.8814 19.2874 25.3442 16.1156 26.2131 11.2486C27.0821 6.38164 29.6297 2.71446e-09 29.6297 2.71446e-09C29.6297 2.71446e-09 34.4526 5.85763 34.2206 9.12928Z"
                fill="#FFC30E"
              />
              <path
                d="M15.4275 46.4723C17.2788 43.7539 23.4437 39.33 23.4437 39.33C23.4437 39.33 26.639 44.8209 23.4313 48.6053C20.2236 52.3896 14.7979 56.6537 14.7979 56.6537C14.7979 56.6537 13.5762 49.1907 15.4275 46.4723Z"
                fill="#FFC30E"
              />
              <path
                d="M44.7459 13.5277C42.8946 16.2461 36.7297 20.6701 36.7297 20.6701C36.7297 20.6701 33.5343 15.1791 36.742 11.3948C39.9498 7.6105 45.3754 3.34641 45.3754 3.34641C45.3754 3.34641 46.5972 10.8094 44.7459 13.5277Z"
                fill="#FFC30E"
              />
              <path
                d="M9.77685 37.2316C12.7515 35.7949 20.3222 35.0188 20.3222 35.0188C20.3222 35.0188 20.3194 41.3577 15.6324 43.0453C10.9454 44.7329 4.09553 45.7369 4.09553 45.7369C4.09553 45.7369 6.80225 38.6683 9.77685 37.2316Z"
                fill="#FFC30E"
              />
              <path
                d="M50.397 22.7684C47.4224 24.2051 39.8517 24.9812 39.8517 24.9812C39.8517 24.9812 39.8544 18.6423 44.5414 16.9547C49.2285 15.2671 56.0783 14.2631 56.0783 14.2631C56.0783 14.2631 53.3716 21.3317 50.397 22.7684Z"
                fill="#FFC30E"
              />
              <path
                d="M9.21078 26.2551C12.5116 26.485 19.4596 29.5648 19.4596 29.5648C19.4596 29.5648 16.2595 35.053 11.349 34.1918C6.43861 33.3305 4.57453e-07 30.8054 4.57453e-07 30.8054C4.57453e-07 30.8054 5.90992 26.0252 9.21078 26.2551Z"
                fill="#FFC30E"
              />
              <path
                d="M50.7892 34.2755C47.4884 34.0456 40.5404 30.9658 40.5404 30.9658C40.5404 30.9658 43.7405 25.4776 48.651 26.3388C53.5614 27.2001 60 29.7252 60 29.7252C60 29.7252 54.0901 34.5054 50.7892 34.2755Z"
                fill="#FFC30E"
              />
              <path
                d="M46.3507 44.1774C43.608 42.3425 39.1446 36.2321 39.1446 36.2321C39.1446 36.2321 44.6845 33.0651 48.5026 36.2444C52.3207 39.4238 56.6229 44.8014 56.6229 44.8014C56.6229 44.8014 49.0933 46.0124 46.3507 44.1774Z"
                fill="#FFC30E"
              />
              <path
                d="M13.8222 15.8226C16.5649 17.6575 21.0283 23.7679 21.0283 23.7679C21.0283 23.7679 15.4883 26.9349 11.6702 23.7556C7.85214 20.5762 3.54998 15.1986 3.54998 15.1986C3.54998 15.1986 11.0795 13.9876 13.8222 15.8226Z"
                fill="#FFC30E"
              />
              <path
                d="M37.0282 50.4826C35.5786 47.5343 34.7957 40.0306 34.7957 40.0306C34.7957 40.0306 41.1911 40.0333 42.8938 44.6788C44.5965 49.3244 45.6094 56.1136 45.6094 56.1136C45.6094 56.1136 38.4778 53.4308 37.0282 50.4826Z"
                fill="#FFC30E"
              />
              <path
                d="M23.1461 9.51744C24.5957 12.4657 25.3787 19.9694 25.3787 19.9694C25.3787 19.9694 18.9832 19.9667 17.2805 15.3212C15.5778 10.6756 14.5649 3.88638 14.5649 3.88638C14.5649 3.88638 21.6966 6.56915 23.1461 9.51744Z"
                fill="#FFC30E"
              />
              <ellipse
                cx="30"
                cy="30"
                rx="10"
                ry="10"
                transform="rotate(-180 30 30)"
                fill="#525252"
              />
              <path
                d="M31 35.7708C31 35.7301 31.0071 35.669 31.0212 35.5875C31.0424 35.506 31.0601 35.2972 31.0742 34.9611C31.0954 34.625 31.1095 34.2227 31.1166 33.7542C31.1307 33.2755 31.1413 32.756 31.1484 32.1958C31.1625 31.6255 31.1731 31.0449 31.1802 30.4542C31.1943 29.8634 31.2085 29.288 31.2226 28.7278C31.2367 28.1574 31.258 27.638 31.2862 27.1694C31.3498 26.1509 31.4346 25.5449 31.5406 25.3514C31.5972 25.1171 31.788 25 32.1131 25C32.1131 25 32.1625 25 32.2615 25C32.2615 25.387 32.2297 26.2019 32.1661 27.4444C32.1095 28.687 32.0777 29.4255 32.0707 29.6597C32.53 29.3236 32.9717 28.962 33.3958 28.575C33.8269 28.1778 34.2261 27.8162 34.5936 27.4903C34.9682 27.1542 35.3074 26.8792 35.6113 26.6653C35.9152 26.4412 36.1413 26.3292 36.2898 26.3292C36.5371 26.3292 36.6608 26.4667 36.6608 26.7417C36.6608 26.9556 36.5477 27.1949 36.3216 27.4597C36.0954 27.7144 35.8127 27.9843 35.4735 28.2694C35.1343 28.5444 34.7633 28.8245 34.3604 29.1097C33.9647 29.3949 33.5972 29.675 33.258 29.95C32.9187 30.2148 32.636 30.4644 32.4099 30.6986C32.1837 30.9329 32.0707 31.1366 32.0707 31.3097C32.1979 31.4218 32.4064 31.5745 32.6961 31.7681C32.9929 31.9514 33.3216 32.1653 33.682 32.4097C34.0495 32.644 34.4276 32.8986 34.8163 33.1736C35.212 33.4384 35.5724 33.7083 35.8975 33.9833C36.6325 34.5843 37 35.0833 37 35.4806C37 35.613 36.9399 35.7046 36.8198 35.7556C36.7703 35.7759 36.7208 35.7861 36.6714 35.7861L36.5654 35.7861C36.2261 35.7861 35.9081 35.669 35.6113 35.4347C35.3145 35.2005 34.9965 34.9204 34.6572 34.5944C34.3251 34.2685 33.947 33.9324 33.523 33.5861C33.0989 33.2296 32.5866 32.9343 31.9859 32.7L31.9859 35.7556C31.9717 35.7963 31.9081 35.8421 31.7951 35.8931C31.6325 35.9644 31.4594 36 31.2756 36C31.0919 36 31 35.9236 31 35.7708Z"
                fill="white"
              />
              <path
                d="M28.982 30.122L29.3023 30.122C29.4319 30.122 29.5539 30.1374 29.6683 30.1683C29.7903 30.1992 29.8513 30.3123 29.8513 30.5077C29.9047 30.9191 29.7255 31.1197 29.3137 31.1094C29.1993 31.0991 29.0926 31.0888 28.9935 31.0785C28.902 31.0683 28.8257 31.0683 28.7647 31.0785C28.7037 31.0785 28.677 31.1043 28.6846 31.1557C28.7609 31.454 28.841 31.7882 28.9248 32.1585C29.0163 32.5288 29.1155 32.899 29.2222 33.2693C29.4967 34.1847 29.756 34.8429 30 35.244C29.8704 35.46 29.7179 35.64 29.5425 35.784C29.3747 35.928 29.2832 36 29.268 36C29.2603 36 29.2527 35.9949 29.2451 35.9846L27.6324 31.4025L24.5098 32.4362L24.3954 33.0842C24.1743 34.2669 23.9265 35.0898 23.652 35.5526C23.6062 35.6554 23.5109 35.6657 23.366 35.5834C23.122 35.46 23 35.3572 23 35.2749C23 35.2543 23.0801 34.9561 23.2402 34.3801C23.4003 33.8041 23.5605 33.223 23.7206 32.6367C23.8883 32.0402 24.0599 31.418 24.2353 30.77C24.4183 30.122 24.5899 29.5049 24.75 28.9187C24.9101 28.3324 25.055 27.8079 25.1846 27.345C25.3143 26.8719 25.4058 26.5325 25.4592 26.3268C25.5125 26.1108 25.5468 25.9257 25.5621 25.7714C25.585 25.6068 25.6155 25.468 25.6536 25.3548C25.7298 25.1183 25.9205 25 26.2255 25C26.5534 25.2468 26.805 25.5708 26.9804 25.972C27.1634 26.3628 27.3159 26.7845 27.4379 27.237C27.5675 27.6896 27.6934 28.1473 27.8154 28.6101C27.9374 29.0729 28.1013 29.4946 28.3072 29.8752C28.3758 30.0192 28.4788 30.0963 28.616 30.1066C28.7533 30.1169 28.8753 30.122 28.982 30.122ZM26.1225 26.7896C26.0615 27.417 25.9243 28.0598 25.7108 28.7181C25.4973 29.3763 25.3257 29.8906 25.1961 30.2609C25.0664 30.6209 24.9559 30.9551 24.8644 31.2637C25.3829 31.2637 26.0196 31.058 26.7745 30.6466C26.9651 30.5334 27.1481 30.4306 27.3235 30.338C26.9651 28.2707 26.5648 27.0879 26.1225 26.7896Z"
                fill="white"
              />
            </svg>
            <h1>{siteTitle}</h1>
          </Link>
        </div>
        <ul className="header__nav--items">
          <li>
            <Link to="/">Projects</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </div>
      <Hamburger />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
